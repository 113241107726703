.tabs {
    padding-top: 50px;
    background: $color-black;
    // .tabs__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .tabs__btn-box
    &__btn-box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    // .tabs__btn
    &__btn {
        flex: 1 1 auto;
        margin-bottom: 20px;
        padding: 0 5px;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 20px;
        line-height: calc(23 / 20 * 100%);
        color: $color-gray;
        border-bottom: 1px solid currentColor;
        text-transform: uppercase;
        text-align: center;
        transition: all 0.3s ease-in;
        &:hover {
            color: $color-blue;
        }
        &.active {
            color: $color-white;
        }
    }
    // .tabs__body-box
    &__body-box {
    }
    // .tabs__body
    &__body {
        display: none;
        position: relative;
        padding-top: 30px;
        color: $color-white;
        &.active {
            display: block;
        }
    }

}

.slider {
    // .slider__item
    &__item {
        margin-bottom: 20px;
    }
    // .slider__item-image
    &__item-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 180px;
        margin-bottom: 20px;
    }
    &__item-name, &__item-text {
        font-family: $font_family_AvantGardeGothicC;
        font-size: 12px;
        line-height: calc(22 / 12 * 100%);
    }
    // .slider__item-name
    &__item-name {
        font-weight: 700;
    }
    // .slider__item-text
    &__item-text {
    }
    // .slider__fraction
    &__fraction {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-family: $font_family_AGAvantGardeCyr;
        &.swiper-pagination-lock {
            display: none;
        }
    }
}

.characteristics {

    color: $color-white;
    // .characteristics__item
    &__item {
        flex: 1 1 100%;
        padding-bottom: 45px;
        margin-bottom: 20px;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 20px;
        font-weight: 700;
        line-height: calc(24 / 20 * 100%);
        text-transform: uppercase;
        @media(min-width:768px) {
            border-bottom: 1px solid $color-dark-gray;
        }
    }
    // .characteristics__table
    &__table {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 30px;
        @media(max-width:767px) {
            flex-direction: column;
        }
    }
    &__name, &__value {
        flex: 0 1 50%;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 18px;
        line-height: calc(21 / 18 * 100%);
        border-bottom: 1px solid $color-dark-gray;
        @media(min-width:768px) {
            min-height: 50px;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }
    // .characteristics__name
    &__name {
        @media(max-width:767px) {
            padding-bottom: 10px;
            margin-bottom: 10px;
        }
        @media(min-width:768px) {
            padding-right: 5px;
        }
    }
    // .characteristics__value
    &__value {
        @media(max-width:767px) {
            border: 1px solid transparent;
            margin-bottom: 30px;
        }
        @media(min-width:768px) {
            text-align: right;
            padding-left: 5px;
        }
    }
}

.documents {
    // .documents__row
    &__row {
        padding-bottom: 50px;
    }
    // .documents__item
    &__item {
        display: flex;
        padding-bottom: 40px;

        border-bottom: 1px solid $color-dark-gray;
        margin-bottom: 100px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    // .documents__logo
    &__logo {
        display: none;
        @media(min-width:600px) {
            display: block;
            flex: 0 0 80px;
            margin-right: 50px;
        }
        @media(min-width:768px) {
            flex: 0 0 100px;
            margin-right: 100px;
        }
    }
    // .documents__info
    &__info {
        flex: 1 1 auto;
        text-align: left;
        margin-right: 15px;
    }
    // .documents__name
    &__name {
        margin-bottom: 30px;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 18px;
        line-height: calc(24 / 20 * 100%);
        font-weight: 700;
        text-transform: uppercase;
        @media(min-width:768px) {
            font-size: 20px;
        }
    }
    // .documents__text
    &__text {
        @extend .text;
        max-width: 355px;
    }
    // .documents__btn
    &__btn {
        flex: 0 0 65px;
        align-self: flex-end;
        color: $color-dark-gray;
        svg {
            margin-bottom: 15px;
        }
        span {
            @extend .text;
            display: block;
            text-transform: uppercase;
            text-align: center;
        }
        &:hover {
            color: $color-blue;
        }
        @media(min-width:500px) {
            margin-right: 20px;
        }
        @media(min-width:768px) {
            margin-right: 50px;
            flex: 0 0 75px;
        }
    }
}
