.footer {
    padding-top: 50px;
    padding-bottom: 30px;
    background: $color-black;
    // .footer__wrap
    &__wrap {
        @include x-container();
        padding-top: 45px;
        border-top: 0.5px solid #FFFFFF;
        margin: 0 auto;
    }
    // .footer__row
    &__row {
        display: flex;
        margin-bottom: 30px;
        @media(max-width:767px) {
            flex-direction: column;
            text-align: center;

        }
    }
    // .footer__info
    &__info {
        flex: 1 0 175px;
        color: $color-white;
        @media(min-width:768px) {
            margin-right: 50px;
        }
        @media(min-width:900px) {
            margin-right: 115px;
        }
    }
    // .footer__logo
    &__logo {
        margin-bottom: 30px;
        img {
            max-width: 175px;
        }
    }
    // .footer__number
    &__number {
        display: inline-block;
        margin-bottom: 15px;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 16px;
        font-weight: 700;
        line-height: calc(19 / 16 * 100%);
        letter-spacing: 0.8px;
        color: $color-light-gray;
    }
    // .footer__adress
    &__adress {
        margin-bottom: 35px;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 12px;
        line-height: calc(18 / 12 * 100%);
        color: #A7A7A7;
    }
    // .footer__social
    &__social {
        display: inline-block;
    }
    // .footer__social-text
    &__social-text {
        padding-bottom: 5px;
        margin-bottom: 15px;
        font-family: $font_family_AGAvantGardeCyr;
        font-size: 12px;
        line-height: calc(14 / 12 * 100%);
        border-bottom: 1px solid $color-semi-gray;
        color: $color-semi-gray;
    }
    // .footer__social-box
    &__social-box {
        display: flex;
        justify-content: space-between;
        svg {
            transition: all 0.3s ease-in;
            &:hover {
                color: $color-blue;
            }
        }
    }
    // .footer__menu
    &__menu {
        overflow: hidden;
        padding-top: 45px;
        @media(min-width:900px) {
            padding-top: 60px;
        }
    }
    // .footer__menu-list
    &__menu-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: 0 -15px 40px;
        @media(min-width:900px) {
            justify-content: space-between;
            border-bottom: 0.5px solid $color-dark-gray;
        }
    }
    // .footer__menu-li
    &__menu-li {
        display: inline-flex;
        padding: 0 15px;
        @media(max-width:899px) {
            padding-top: 15px;
            border-bottom: 0.5px solid $color-dark-gray;
        }
    }
    // .footer__menu-link
    &__menu-link {
        @extend .text;
        text-transform: uppercase;
        color: $color-gray;
        padding-bottom: 18px;
        border-bottom: 1px solid transparent;
        transition: all 0.3s ease-in;
        &:hover {
            color: $color-blue;
            border-bottom: 1px solid currentColor;
        }
    }
    // .footer__desc
    &__desc {
        font-family: $font_family_AGAvantGardeCyr;
        color: #606060;
    }
    // .footer__bottom
    &__bottom {
        display: flex;
        padding-top: 15px;
        border-top: 1px solid currentColor;
        color: #606060;
        @media(max-width:599px) {
            flex-direction: column;
        }
        @media(min-width:600px) {
            justify-content: space-between;
        }
    }
    &__copy, &__creator {
        font-family: $font_family_AGAvantGardeCyr;
        font-size: 12px;
    }
    // .footer__copy
    &__copy {
        line-height: calc(18 / 12 * 100%);
        @media(max-width:599px) {
            margin-bottom: 15px;
        }
        @media(min-width:600px) {
            margin-right: 20px;
        }
    }
    // .footer__creator
    &__creator {
        white-space: nowrap;
        line-height: calc(14 / 12 * 100%);
        a {
            transition: all 0.3s ease-in;
            &:hover {
                color: $color-blue;
                text-decoration: underline;
            }
        }
        @media(max-width:599px) {
            align-self: center;
        }
    }
}
