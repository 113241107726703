.wrapper {
    min-width: 320px;
    max-width: 100%;
    overflow: hidden;
}

.text {
    font-family: $font_family_AGAvantGardeCyr;
    font-size: 14px;
    line-height: calc(16 / 14 * 100%);
}

.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    padding: 0 35px;
    border-radius: 15px;
    border: 1px solid $color-white;
    @extend .text;
    text-transform: uppercase;
}

.admin {
    color: $color-white;
    h1 {
        font-family: $font_family_AGAvantGardeCyr;
        font-size: 24px;
        line-height: calc(31 / 24 * 100%);
        font-weight: 700;
        letter-spacing: 1px;
        @media(min-width:768px) {
            font-size: 36px;
            line-height: calc(43 / 36 * 100%);
        }
    }
    p {
        @extend .text;
    }
    a {
        color: $color-blue;
        transition: all 0.3s ease-in;
        &:hover {
            text-decoration: underline;
        }
    }
}
