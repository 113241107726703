// ################################################################################
// Css variables
// Основное преимущество перед Sass - возможность использования медиа запросов
:root {
    // Padding, необходимый для контейнера (по-умолчанию)
    --padding: 15px;
    // Ширина органичивающего контейнера с учетом padding'a (по-умолчанию)
    --wrap: 1150px;
  }
// ################################################################################

// Sass variables

// ################################################################################
// Контейнер (оболочка) - max-width и padding
@mixin x-container($max-width: var(--wrap), $padding: var(--padding)) {
  @if ($max-width and $padding) {
    max-width: calc(#{$max-width} + (#{$padding} * 2));
    padding: 0 #{$padding};
  } @else if (#{$max-width}) {
    max-width: calc(#{$max-width} + (var(--padding) * 2));
    padding: 0 var(--padding);
  } @else if (#{$padding}) {
    max-width: calc(var(--wrap) + (#{$padding} * 2));
    padding: 0 #{$padding};
  } @else {
    max-width: calc(var(--wrap) + (var(--padding) * 2));
    padding: 0 var(--padding);
  }
}
