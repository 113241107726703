.map {
    padding-top: 50px;
    background: $color-black;
    // .map__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .map__content
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 65px;
        color: $color-white;
    }
    // .map__h2
    &__h2 {
        margin-bottom: 55px;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 36px;
        font-weight: 700;
        line-height: calc(43 / 36 * 100%);
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    // .map__dropdown
    &__dropdown {
        position: relative;
        display: flex;
        align-items: center;
        &::after {
            content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE4LjgwNi4xOTNhLjY4LjY4IDAgMDAtLjk1IDBMOS41MSA4LjQyMiAxLjE0Ni4xOTNhLjY4LjY4IDAgMDAtLjk1IDAgLjY1My42NTMgMCAwMDAgLjkzNGw4LjgyMiA4LjY4YS42Ni42NiAwIDAwLjQ3NS4xOTMuNjkuNjkgMCAwMC40NzQtLjE5M2w4LjgyMi04LjY4YS42NC42NCAwIDAwLjAxNy0uOTM0eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==");
            position: absolute;
            right: 15px;
            top: 9px;
            pointer-events: none;
        }
        @media (max-width: 649px) {
            flex-direction: column;
            &::after {
                top: 66px;
            }
        }
    }
    // .map__label
    &__label {
        margin-right: 35px;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 24px;
        line-height: calc(28 / 24 * 100%);
        letter-spacing: 0.6px;
        color: $color-semi-gray;
        @media (max-width: 649px) {
            margin: 0 0 30px 0;
        }
    }
    // .map__select
    &__select {
        @extend .text;
        display: inline-flex;
        align-items: center;
        height: 36px;
        padding: 0 50px 0 15px;
        border: 1px solid #888888;
        letter-spacing: 0.6px;
        color: $color-semi-gray;
        appearance: none;
        option {
            background: $color-black;
        }
        @media (min-width: 650px) {
            width: 380px;
        }
    }
    // делает серым поверхность, но не метки
    #map .ymaps-2-1-79-ground-pane {
        filter: grayscale(1);
    }
}

.shop {
    padding-top: 100px;
    background: $color-black;
    // .shop__content
    &__content {
        position: relative;
        @include x-container(1050px, 0px);
        margin: 0 auto;
    }
    // .shop__item
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    // .shop__img-link
    &__img-link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 200px;
        margin-bottom: 30px;
    }
    &__balloon-link,
    &__address,
    &__phone,
    &__site,
    &__mail {
        font-family: $font_family_AvantGardeGothicC;
        font-size: 24px;
        font-weight: 700;
        line-height: calc(40 / 24 * 100%);
    }
    // .shop__balloon-link
    &__balloon-link {
        color: $color-white;
    }
    // .shop__item-info
    &__item-info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    // .shop__address
    &__address {
        color: $color-blue;
    }
    // .shop__phone
    &__phone {
        color: $color-blue;
    }
    // .shop__site
    &__site {
        color: $color-blue;
    }
    // .shop__mail
    &__mail {
        color: $color-blue;
    }
    // .shop__btn
    &__btn {
        position: absolute;
        top: 50%;
        color: $color-white;
        transition: all 0.3s ease-in;
        z-index: 2;
        &:hover {
            color: $color-blue;
        }
        // .shop__btn_prev
        &_prev {
            left: 12px;
            @media(min-width:1200px) {
                left: -50px;
            }
            @media(min-width:1440px) {
                left: -150px;
            }
        }
        // .shop__btn_next
        &_next {
            right: 12px;
            transform: rotate(180deg);
            @media(min-width:1200px) {
                right: -50px;
            }
            @media(min-width:1440px) {
                right: -150px;
            }
        }
        &.swiper-button-lock {
            display: none;
        }
    }
}
