@font-face {
    font-family: 'AGAvantGardeCyr';
    src: url('AGAvantGardeCyrBook.eot');
    src: url('AGAvantGardeCyrBook.eot?#iefix') format('embedded-opentype'),
        url('AGAvantGardeCyrBook.woff2') format('woff2'),
        url('AGAvantGardeCyrBook.woff') format('woff'),
        url('AGAvantGardeCyrBook.ttf') format('truetype'),
        url('AGAvantGardeCyrBook.svg#AGAvantGardeCyrBook') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

