.form {
    // .form__fieldset
    &__fieldset {
        // .form-field
        .form-field {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            label {
                display: none;
            }
            input {
                border: 1px solid $color-light-gray;
                height: 50px;
                padding: 0 15px;
            }
        }
    }
    .button-line {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            @extend .btn;
            height: 50px;
            transition: all 0.3s ease-in;
            &:hover {
                border-color: $color-blue;
                color: $color-blue;
            }
        }
    }
    &  + .form-message {
        display: none;

    }
    &.success + .form-message {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 15px;
        background-color: $color-black;
        color: $color-white;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 30px;
        text-align: center;
    }
}
