.text-block {
    @include x-container();
    margin: 0 auto;
    @extend .admin;
    h1 {
        margin-bottom: 30px;
    }
    p {
        margin-bottom: 10px;
    }
}
