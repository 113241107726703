*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
}
ul,
li {
    list-style: none;
}
hr, fieldset {
    border: none;
}
option,
select,
input,
button,
textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: transparent;
    &::placeholder {
        color: inherit;
    }
}
option,
select,
button {
    cursor: pointer;
}
a {
    color: inherit;
    text-decoration: transparent;
    cursor: pointer;
    color: inherit;
    font-size: inherit;
}
img, svg {
    max-width: 100%;
}
html,
body {
    height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    background: $color-black;
    &.lock {
        overflow: hidden;
    }
}
