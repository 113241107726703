// fonts
$font_family_AGAvantGardeCyr: "AGAvantGardeCyr", sans-serif;
$font_family_AvantGardeGothicC: "AvantGardeGothicC", sans-serif;
$font_family_Montserrat: "Montserrat", sans-serif;
$font_family_Roboto: "Roboto", sans-serif;

// colors
$color-black: #000000;
$color-white: #ffffff;

$color-blue: #48B2E6;
$color-gray: #A7B5BF;
$color-semi-gray: #AAAAAA;
$color-light-gray: #DFDFDF;
$color-dark-gray: #929FA7;
