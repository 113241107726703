.video {
    padding-top: 50px;
    padding-bottom: 50px;
    background: $color-black;
    // .video__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .video__item
    &__item {
        position: relative;
        // 16:9 aspect radio
        padding-bottom: 56.25%;
        margin-bottom: 20px;
        overflow: hidden;
        @media(min-width:900px) {
            padding-bottom: 35.25%;
        }
    }
    // .video__poster
    &__poster {
        .video__poster-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 2;
            cursor: pointer;
        }
        &:hover {
            .video__poster-btn {
                color: $color-blue;
            }
        }
    }
    // .video__poster-btn
    &__poster-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        color: $color-white;
        transition: all 0.3s ease-in;
    }
    // .video__video
    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }
    // .video__info
    &__info {
        color: $color-white;
    }
    // .video__name
    &__name {
        margin-bottom: 25px;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 24px;
        line-height: calc(22 / 24 * 100%);
        font-weight: 700;
    }
    // .video__text
    &__text {
        @extend .text;
    }
}
