@font-face {
    font-family: 'AvantGardeGothicC';
    src: url('AvantGardeGothicC-Bold.eot');
    src: url('AvantGardeGothicC-Bold.eot?#iefix') format('embedded-opentype'),
        url('AvantGardeGothicC-Bold.woff2') format('woff2'),
        url('AvantGardeGothicC-Bold.woff') format('woff'),
        url('AvantGardeGothicC-Bold.ttf') format('truetype'),
        url('AvantGardeGothicC-Bold.svg#AvantGardeGothicC-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'AvantGardeGothicC';
    src: url('AvantGardeGothicC.eot');
    src: url('AvantGardeGothicC.eot?#iefix') format('embedded-opentype'),
        url('AvantGardeGothicC.woff2') format('woff2'),
        url('AvantGardeGothicC.woff') format('woff'),
        url('AvantGardeGothicC.ttf') format('truetype'),
        url('AvantGardeGothicC.svg#AvantGardeGothicC') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

