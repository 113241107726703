.product {
    padding-top: 50px;
    padding-bottom: 50px;
    background: $color-black;
    // .product__wrap
    &__wrap {
        @include x-container();
        margin: 0 auto;
    }
    // .product__row
    &__row {
        display: flex;
        margin-bottom: 50px;
        @media(max-width:767px) {
            flex-direction: column;
            align-items: center;
        }
    }
    // .product__image
    &__image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        img {
            margin-bottom: 30px;
        }
        @media(max-width:767px) {
            margin-bottom: 20px;
        }
        @media(min-width:768px) {
            flex: 1 0 50%;
            margin-right: 25px;
        }
        @media(min-width:900px) {
            margin-right: 50px;
        }
    }
    // .product__info
    &__info {
        color: $color-white;
        p {
            @extend .text;
            line-height: 22px;
            button {
                display: block;
                margin: 20px auto 0;
                &.show {

                }
                &.hide {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
                &::after {
                    content: '';
                    display: inline-block;
                    width: 19px;
                    height: 10px;
                    margin-left: 15px;
                    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE4LjgwNi4xOTNhLjY4LjY4IDAgMDAtLjk1IDBMOS41MSA4LjQyMiAxLjE0Ni4xOTNhLjY4LjY4IDAgMDAtLjk1IDAgLjY1My42NTMgMCAwMDAgLjkzNGw4LjgyMiA4LjY4YS42Ni42NiAwIDAwLjQ3NS4xOTMuNjkuNjkgMCAwMC40NzQtLjE5M2w4LjgyMi04LjY4YS42NC42NCAwIDAwLjAxNy0uOTM0eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==');
                }
                @media(min-width:768px) {
                    flex: 1 1 50%;
                    margin: 20px 0 0;
                }
            }
        }
    }
    // .product__offer
    &__offer {
        color: $color-white;
        display: flex;
        @media(max-width:449px) {
            flex-direction: column;
        }
        @media(min-width:450px) {
            justify-content: center;
            align-items: center;
        }
    }
    // .product__btn
    &__btn {
        @extend .btn;
        min-width: 190px;
        height: 40px;
        border-radius: 25px;
        transition: all 0.3s ease-in;
        &:hover {
            border: 1px solid $color-blue;
            color: $color-blue;
        }
        @media(max-width:449px) {
            margin-bottom: 20px;
        }
        @media(min-width:450px) {
            margin-right: 25px;
        }
    }
    //.product__price
    &__price {
        font-family: $font_family_AGAvantGardeCyr;
        font-size: 36px;
        line-height: calc(42 / 36 * 100%);
        .icon-rub {
            width: 20px;
            height: 20px;
        }
        @media(max-width:449px) {
            text-align: center;
        }
    }
}
