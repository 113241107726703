.modal__bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba($color: $color-black, $alpha: 0.9);
    &.opened {
        display: block;
        overflow: auto;
    }
    .modal-title {
        font-size: 48px;
        line-height: calc(57 / 48 * 100%);
        font-weight: 700;
        text-align: center;
        margin-bottom: 30px;
    }
}

.modal__layout {
    padding: 50px 10px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    &.opened {

    }
}

.modal__container {
    position: relative;
    padding: 35px 25px 55px;
    background-color: $color-black;
    border: 1px solid $color-blue;
    color: $color-white;
    font-family: $font_family_AvantGardeGothicC;
    overflow: hidden;
}

.modal__content {
    position: relative;
    margin-top: 20px;
    z-index: 2;
}

.modal__closer {
    display: block;
    width: 40px;
    height: 40px;
    background: no-repeat url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNDEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE3LjM3NyAxNi41bDYuMjY2LTYuMjY2YS42Mi42MiAwIDEwLS44NzYtLjg3N0wxNi41IDE1LjYyNGwtNi4yNjYtNi4yNjdhLjYyLjYyIDAgMDAtLjg3Ny44NzdsNi4yNjcgNi4yNjYtNi4yNjcgNi4yNjZhLjYyLjYyIDAgMTAuODc3Ljg3N2w2LjI2Ni02LjI2NyA2LjI2NyA2LjI2N2EuNjE4LjYxOCAwIDAwLjg3NiAwIC42Mi42MiAwIDAwMC0uODc3TDE3LjM3NyAxNi41eiIgZmlsbD0iIzQ4QjJFNiIvPjxnIGZpbHRlcj0idXJsKCNwcmVmaXhfX3ByZWZpeF9fcHJlZml4X19maWx0ZXIwX2QpIj48cGF0aCBkPSJNMTYuNSAxLjI0YTE1LjIxIDE1LjIxIDAgMDExMC43OSA0LjQ3IDE1LjIxMSAxNS4yMTEgMCAwMTQuNDcgMTAuNzkgMTUuMjExIDE1LjIxMSAwIDAxLTQuNDcgMTAuNzkgMTUuMjExIDE1LjIxMSAwIDAxLTEwLjc5IDQuNDcgMTUuMjEzIDE1LjIxMyAwIDAxLTEwLjc5LTQuNDdBMTUuMjEyIDE1LjIxMiAwIDAxMS4yNCAxNi41IDE1LjIxMiAxNS4yMTIgMCAwMTUuNzEgNS43MSAxNS4yMTIgMTUuMjEyIDAgMDExNi41IDEuMjR6bTAtMS4yNEM3LjM4NyAwIDAgNy4zODcgMCAxNi41UzcuMzg3IDMzIDE2LjUgMzMgMzMgMjUuNjEzIDMzIDE2LjUgMjUuNjEzIDAgMTYuNSAweiIgZmlsbD0iIzQ4QjJFNiIvPjwvZz48ZGVmcz48ZmlsdGVyIGlkPSJwcmVmaXhfX3ByZWZpeF9fcHJlZml4X19maWx0ZXIwX2QiIHg9IjAiIHk9IjAiIHdpZHRoPSI0MSIgaGVpZ2h0PSI0MSIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+PGZlQ29sb3JNYXRyaXggaW49IlNvdXJjZUFscGhhIiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIiByZXN1bHQ9ImhhcmRBbHBoYSIvPjxmZU9mZnNldCBkeD0iNCIgZHk9IjQiLz48ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIyIi8+PGZlQ29sb3JNYXRyaXggdmFsdWVzPSIwIDAgMCAwIDAuODQzMTM3IDAgMCAwIDAgMC4wOTQxMTc2IDAgMCAwIDAgMC4xNjQ3MDYgMCAwIDAgMC40IDAiLz48ZmVCbGVuZCBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJlZmZlY3QxX2Ryb3BTaGFkb3ciLz48ZmVCbGVuZCBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2Ryb3BTaGFkb3ciIHJlc3VsdD0ic2hhcGUiLz48L2ZpbHRlcj48L2RlZnM+PC9zdmc+);
    background-size: contain;
}

.modal__loader {

}
