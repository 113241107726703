.header {
    position: relative;
    background-color: $color-black;
    overflow: hidden;
    // .header__wrap
    &__wrap {
        margin: 0 auto;
        // .header__wrap_top
        &_top {
            // display: none;
            @include x-container(1150px, 0px);
            position: relative;
            height: 945px;
            z-index: 3;
        }
    }
    // .header__top
    &__top {
        height: 178px;
        background: $color-black;
    }
    // .header__top-bg
    &__top-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    // .header__bottom
    &__bottom {
        position: relative;
        height: 767px;
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: $color-black, $alpha: 0.75);
            z-index: 2;
        }
    }
    // .header__bottom-bg
    &__bottom-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    // .header__menu
    &__menu {
        position: relative;
        z-index: 4;
        background-color: rgba($color: $color-black, $alpha: 0.75);
    }
    // .header__slogan
    &__slogan {
        @include x-container(1120px);
        position: absolute;
        bottom: 65px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 48px;
        line-height: calc(56 / 48 * 100%);
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1px;
        color: $color-white;
        z-index: 3;
        @media(max-width:499px) {
            font-size: 38px;
        }
    }
    // .header__404
    &__404 {
        @include x-container();
        position: relative;
        margin: 80px auto 180px;
        padding-top: 150px;
        padding-bottom: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        color: $color-white;
        text-align: center;
        z-index: 10;
        background: center / contain $color-black url('/static/images/base/4O4.png') no-repeat;

        font-family: $font_family_AvantGardeGothicC;
        letter-spacing: 1px;
    }
    // .header__404-title
    &__404-title {
        margin-bottom: 20px;
        font-size: 48px;
        line-height: calc(57 / 48 * 100%);
        font-weight: 700;
    }
    // .header__404-subtitle
    &__404-subtitle {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: calc(29 / 24 * 100%);
        font-weight: 700;
    }
    // .header__404-text
    &__404-text {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: calc(21 / 18 * 100%);
    }
    // .header__btn
    &__btn {
        @extend .text;
        @extend .btn;
        background: $color-blue;
        border: 1px solid $color-blue;
        transition: all 0.3s ease-in;
        &:hover {
            background: transparent;
        }
    }
}

.header-video {
    // .header-video__item
    &__item {
        position: relative;
        padding-bottom: 56.25%;
        margin-bottom: 20px;
        min-height: 767px;
        overflow: hidden;
    }
    // .header-video__poster
    &__poster {
        @extend .video__poster;
    }
    // .header-video__video
    &__video {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 426%;
        height: 100%;
        @media(min-width:1350px) {
            width: 100%;
            height: 100%;
        }
    }
}



.menu {
    // .menu__top
    &__top {
        padding: 0 15px;
    }
    // .menu__top-container
    &__top-container {
    }
    // .menu__model
    &__model {
        @include x-container(995px, 0px);
        margin: 0 auto;
        height: 114px;
        color: $color-white;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    // .menu__logo
    &__logo {
        flex: 0 1 50%;
        align-self: flex-end;
        .icon-logo {
            max-width: 100%;
        }
    }
    // .menu__name
    &__name {
        flex: 0 1 50%;
        align-self: flex-end;
        font-family: $font_family_AvantGardeGothicC;
        text-align: right;
        font-size: 35px;
        @media(min-width:600px) {
            font-size: 45px;
            line-height: calc(52 / 45 * 100%);
        }
    }
    // .menu__desc
    &__desc {
        flex: 1 1 100%;
        align-self: center;
        text-align: center;
        font-family: $font_family_AvantGardeGothicC;
        font-size: 14px;
        line-height: calc(16 / 14 * 100%);
        @media(min-width:600px) {
            text-align: right;
            align-self: flex-start;
        }
    }
    // .menu__bottom
    &__bottom {
        padding: 0 15px;
        background: $color-black;
    }
    // .menu__list
    &__list {
        @include x-container(995px, 0px);
        margin: 0 auto;
        display: flex;
        align-items: center;
        color: $color-white;
        @media(max-width:767px) {
            flex-wrap: wrap;
            text-align: center;
        }
    }
    // .menu__item
    &__item {
        white-space: nowrap;
        // .menu__item_link
        &_link {
            flex: 1 1 calc(100% / 3);
            @media(min-width:768px) {
                margin-right: 10px;
                flex: 0 1 160px;
            }
        }
        // .menu__item_number
        &_number {
            height: 64px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex: 1 1 50%;
            @media(min-width:768px) {
                justify-content: flex-end;
                margin-right: 10px;
                flex: 1 1 180px;
            }
        }
        // .menu__item_btn
        &_btn {
            height: 64px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex: 1 1 50%;
            @media(min-width:768px) {
                justify-content: flex-end;
                flex: 0 1 240px;
            }
        }
    }
    // .menu__link
    &__link {
        height: 64px;
        display: inline-flex;
        align-items: center;
        @extend .text;
        text-transform: uppercase;
        border-top: 5px solid transparent;
        padding-bottom: 5px;
        transition: all 0.3s ease-in;
        &:hover {
            border-top: 5px solid $color-white;
        }
        @media(max-width:500px) {
            font-size: 13px;
        }
    }
    // .menu__number
    &__number {
        font-family: $font_family_AvantGardeGothicC;
        font-size: 16px;
        line-height: calc(19 / 16 * 100%);
        font-weight: 700;
        letter-spacing: 0.8px;
        transition: all 0.3s ease-in;
        &:hover {
            color: $color-blue;
        }
        @media(max-width:499px) {
            font-size: 14px;
        }
    }
    // .menu__btn
    &__btn {
        @extend .btn;
        transition: all 0.3s ease-in;
        &:hover {
            color: $color-blue;
            border: 1px solid $color-blue;
        }
        @media(max-width:499px) {
            padding: 0 10px;
        }
    }
}
